import React from "react";
import { ThemeProvider } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import LandingPage from "./pages/LandingPage";
import DetailsPage from "./pages/DetailsPage"; // Import the new DetailsPage component
import useAnalytics from "./useAnalytics";
import theme from "./theme";

function App() {
  useAnalytics();
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/:pename" element={<DetailsPage />} /> {/* Add new route */}
          {/* Redirect all other paths to Home */}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;

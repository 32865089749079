import React from "react";
import { Typography, Link } from "@mui/material";
import ads from "../assets/img/ads.png"; // Adjust the path to your image

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"powered by "}
      <Link color="inherit" href="https://accentrixcloud.com/">
        <img
          src={ads}
          alt="AccentrixCloud Logo"
          style={{ height: "20px", verticalAlign: "middle" }}
        />
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      <Typography>A product of Accentrix Data Systems Ltd</Typography>
    </Typography>
  );
}

export default Copyright;

import React, { useState, useEffect } from "react";
import {
  Container,
  useTheme,
  Modal,
  Typography,
  Box,
  CircularProgress,
  Button,
  Grid
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import InfoCard from "../component/InfoCard";
import SearchBar from "../component/SearchBar";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Copyright from "../component/Copyright";
import bg5 from "../assets/img/bg5.jpg";
import bg6 from "../assets/img/bg6.jpg";
import bg1 from "../assets/img/bg1.jpg"; // Ensure these images are imported correctly
import bg2 from "../assets/img/bg2.jpg";
import bg3 from "../assets/img/bg3.jpg";

const images = [
  {
    url: bg5,
    text: "Latest Business Insights",
    subtitle: "Stay Updated With Tech Trends",
  },
  {
    url: bg6,
    text: "Access to New Tenders",
    subtitle: "Find and Bid on New Projects",
  },
  {
    url: bg1,
    text: "Enhanced Data Security",
    subtitle: "Secure Your Data With State-of-the-Art Solutions",
  },
  {
    url: bg2,
    text: "Scalable Solutions",
    subtitle: "Grow Your Business With the Right Tools",
  },
  {
    url: bg3,
    text: "24/7 Support",
    subtitle: "Round-the-Clock Assistance for All Your Needs",
  },
];

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  fade: true,
};

const LandingPage = () => {
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize useNavigate
  const [search, setSearch] = useState("");
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSlider, setShowSlider] = useState(true);
  const [selectedPename, setSelectedPename] = useState(null);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchCards();
  }, []);

  const fetchCards = async () => {
    try {
      const fetchTenders = axios.get("https://api.accentrixcloud.com/open_tenders");
      const tenders = await fetchTenders.then(res => res.data);
      const groupedTenders = tenders.reduce((acc, curr) => {
        const key = curr.pename;
        if (!acc[key]) acc[key] = [];
        acc[key].push(curr);
        return acc;
      }, {});
      const tendersFormatted = Object.keys(groupedTenders).map((key) => ({
        pename: key,
        details: groupedTenders[key],
      }));
      setCards(tendersFormatted);
      setFilteredCards(tendersFormatted);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch data");
      setOpen(true);
      setLoading(false);
    }
  };

  const handleCardClick = (pename) => {
    navigate(`/${pename}`); // Navigate to the new route
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    if (!selectedPename) {
      const filtered = cards.filter((card) =>
        card.pename.toLowerCase().includes(value)
      );
      setFilteredCards(filtered);
    } else {
      const specificDetails =
        cards.find((card) => card.pename === selectedPename)?.details || [];
      const filteredDetails = specificDetails.filter((detail) =>
        detail.title.toLowerCase().includes(value)
      );
      setFilteredCards(
        filteredDetails.map((detail) => ({
          title: detail.title,
          pename: selectedPename,
        }))
      );
    }
  };

  return (
    <Container maxWidth="100vw">
      <Slider {...sliderSettings}>
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              height: 450,
              backgroundImage: `url(${image.url})`,
              backgroundSize: "cover",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                bottom: theme.spacing(2),
                width: "100%",
                textAlign: "center",
                color: theme.palette.common.white,
                fontSize: {
                  xs: "16px",
                  sm: "18px",
                  md: "24px",
                },
                padding: theme.spacing(1),
              }}
            >
              <Typography variant="h6" component="h2">
                {image.text}
              </Typography>
              <Typography variant="body1">{image.subtitle}</Typography>
            </Box>
          </Box>
        ))}
      </Slider>
      <Box sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          marginTop: 3,
          marginBottom: 3,
        }}>
          <SearchBar value={search} onChange={handleSearchChange} />
        </Box>
        <Container sx={{
      display: 'flex',
      flexDirection: 'column', // Use column layout to manage vertical spacing
      alignItems: 'center', // Center align items
      padding: { xs: '0 10px', sm: '0 15px', md: '0 20px', lg: '0 20px' },
      maxWidth: { xs: '100%', sm: '100%', md: '960px', lg: '1280px' }
    }}>
      {loading ? (
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 3,
          marginBottom: 3,
        }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2} justifyContent="center"> {/* Using Grid container */}
          {filteredCards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}> {/* Responsive Grid items */}
              <InfoCard
                title={card.pename}
                description={`Tenders: ${card.details.length}`}
                onClick={() => handleCardClick(card.pename)}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6">Error</Typography>
          <Typography id="modal-description">{error}</Typography>
        </Box>
      </Modal>
    </Container>
      <Copyright />
    </Container>
  );
};

export default LandingPage;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import axios from "axios";

const DetailsPage = () => {
  const { pename } = useParams(); // Get the parameter from the route
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedTender, setSelectedTender] = useState(null);
  const [similarTenders, setSimilarTenders] = useState([]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get(`https://api.accentrixcloud.com/open_tenders?pename=${pename}`);
        setDetails(response.data);
        setLoading(false);
        document.title = `${pename} - Details`; // Set the page title for SEO
      } catch (error) {
        setError("Failed to fetch details");
        setLoading(false);
      }
    };

    fetchDetails();
  }, [pename]);

  const handleCardClick = (tender) => {
    setSelectedTender(tender);
    fetchSimilarTenders(tender.procurementcategory);
  };

  const fetchSimilarTenders = async (category) => {
    try {
      const response = await axios.get(`https://api.accentrixcloud.com/open_tenders?category=${category}`);
      setSimilarTenders(response.data.filter(tender => tender.pename !== pename));
    } catch (error) {
      setError("Failed to fetch similar tenders");
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "50px" }}>
      <Typography variant="h4" gutterBottom>{pename}</Typography>
      {loading ? (
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 3,
          marginBottom: 3,
        }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : selectedTender ? (
        <>
          <Card sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h6">{selectedTender.title}</Typography>
              <Typography variant="body2">Category: {selectedTender.procurementcategory}</Typography>
              <Typography variant="body2">Due Date: {new Date(selectedTender.duedate).toLocaleDateString()}</Typography>
              <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>Bid</Button>
            </CardContent>
          </Card>
          <Typography variant="h6" gutterBottom>More tenders like this</Typography>
          {similarTenders.map((tender, index) => (
            <Card key={index} sx={{ marginBottom: 2 }} onClick={() => handleCardClick(tender)}>
              <CardContent>
                <Typography variant="h6">{tender.title}</Typography>
                <Typography variant="body2">{tender.description}</Typography>
              </CardContent>
            </Card>
          ))}
        </>
      ) : (
        details.map((detail, index) => (
          <Card key={index} sx={{ marginBottom: 2 }} onClick={() => handleCardClick(detail)}>
            <CardContent>
              <Typography variant="h6">{detail.title}</Typography>
              <Typography variant="body2">{detail.description}</Typography>
            </CardContent>
          </Card>
        ))
      )}
    </Container>
  );
};

export default DetailsPage;

import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import LogoImage from '../assets/img/logo.png'; // Ensure to import your logo correctly

const SearchBar = ({ value, onChange }) => {
  return (
    <Box sx={{
      
      backgroundColor: 'white',
      padding: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',  // Center content vertically in the box
      marginTop: 3,
      marginBottom: 3,
    }}>
      <Box
        component="img"
        src={LogoImage}
        alt="Company Logo"
        sx={{ width: 100, height: 'auto' }}  // Adjust size as needed
      />
      <Typography alignSelf='center' sx={{marginBottom: 1, color:'#df326f'}} >Accentrix Cloud</Typography>
      <TextField
        fullWidth
        label="Search Keyword or Business Entity"
        variant="outlined"
        value={value}
        onChange={onChange}
        margin="normal"
        
      />
    </Box>
  );
};

export default SearchBar;

// InfoCard.js
import React from 'react';
import { Card, CardContent, Typography, CardActionArea } from '@mui/material';

const InfoCard = ({ title, description, onClick }) => {
  return (
    <Card onClick={onClick} sx={{ display: 'flex', justifyContent: 'center', overflowX: 'hidden', maxWidth: 245, m: 1 }}>
  <CardActionArea component="article">
    <CardContent>
      <Typography gutterBottom variant="caption" component="h2" sx={{ color: '#df326f' }}>
        {title}
      </Typography>
      <Typography variant="body2" component="p" color="text.secondary">
        {description}
      </Typography>
    </CardContent>
  </CardActionArea>
</Card>
  );
};

export default InfoCard;
